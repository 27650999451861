import { IconTools } from '@veneer/core';
import tokens from '@veneer/tokens';
import { MainTheme } from 'src/styles/styles';
import styled from 'styled-components';

export const ParentContainer = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
`;

export const Container = styled.div`
  display: grid;
  gap: ${tokens.layout.size5};
  padding: ${tokens.layout.size5} 40px;
  max-width: 1140px;
  margin: 0 auto;

  @media (${MainTheme.mediaQueries.medium}) {
    gap: ${tokens.layout.size8};
    padding: ${tokens.layout.size5} ${tokens.layout.size8};
  }

  @media (${MainTheme.mediaQueries.small}) {
    gap: ${tokens.layout.size5};
    padding: ${tokens.layout.size5};
  }

  @media (${MainTheme.mediaQueries.maxWidthMobile}) {
    gap: ${tokens.layout.size4};
    padding: ${tokens.layout.size4};
  }
`;

export const StyledIconTools = styled(IconTools)`
  && {
    color: ${tokens.color.gray6};
    height: ${tokens.layout.size8};
    width: ${tokens.layout.size8};
  }
`;

export const ErrorContent = styled.div`
  width: 100%;
  display: flex;
  padding: 48px ${tokens.layout.size0};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${tokens.layout.size4};
  align-self: stretch;
  background-color: ${tokens.color.gray0};
`;

export const ErrorTopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${tokens.layout.size2};
  align-self: stretch;
`;

export const ErrorTextContainer = styled.div`
  display: flex;
  padding: ${tokens.layout.size0} ${tokens.layout.size4};
  flex-direction: column;
  align-items: center;
  gap: ${tokens.layout.size1};
  align-self: stretch;
  > div {
    text-align: center;
  }
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 84vh;
`;
